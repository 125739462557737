import DatasetReviewPage from '../../components/DatasetReviewPage';

const ImageLevelDatasetReview = () => {
  return (
    <DatasetReviewPage
      saveToEnml={false}
      hideNewImageAnnotations={true}
      path="/imagelevel"
    />
  );
};

export default ImageLevelDatasetReview;
