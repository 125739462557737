import React, { useState, useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { Modal, Button } from 'antd';
import LoadingDots from '../LoadingDots';
import Lens from '../Lens';
import SelectionBox from '../SelectionBox';
import { CONDITIONS_MAP } from '../../enums';

const LensImage = ({
  image,
  annotations,
  hiddenAnnotations,
  setAnnotations,
  sidebarActiveAnnotation,
  newAnnotations,
  setNewAnnotations,
  categoryIds,
  conditionsLabelMap,
  categoryToConditionsMap,
  imgMaxHeight = 'calc(100vh - 180px)',
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [imageOrientation, setImageOrientation] = useState(null);
  const [displayWidth, setDisplayWidth] = useState(null);
  const [displayHeight, setDisplayHeight] = useState(null);
  const [displayRatio, setDisplayRatio] = useState(null);
  const [offsetTop, setOffsetTop] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(null);
  const [displayLenses] = useState(true);
  const [selectionBox, setSelectionBox] = useState({});
  const [mouseCoors, setMouseCoors] = useState({});
  const [imageCoors, setImageCoors] = useState({});
  const [isDrawing, setIsDrawing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (image) {
      const or = image.width >= image.height ? 'landscape' : 'portrait';
      setImageSrc(image.src);
      setImageWidth(image.width);
      setImageHeight(image.height);
      setImageOrientation(or);
    }
  }, [image]);

  const baseImage = useRef(null);

  let dragImg = new Image(0, 0);
  dragImg.src =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  const updateDisplayDimensions = useCallback(() => {
    if (baseImage.current) {
      const dimensions = baseImage.current.getBoundingClientRect();
      const { offsetTop, offsetLeft } = baseImage.current;
      setDisplayWidth(dimensions.width);
      setDisplayHeight(dimensions.height);
      setOffsetTop(offsetTop);
      setOffsetLeft(offsetLeft);
    }
  }, []);

  const handleImageLoaded = () => {
    updateDisplayDimensions();
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        updateDisplayDimensions();
      },
      false
    );
    return () => window.removeEventListener('resize', updateDisplayDimensions);
  }, [updateDisplayDimensions]);

  useEffect(() => {
    const displayRatio =
      imageOrientation === 'landscape'
        ? displayWidth / imageWidth
        : displayHeight / imageHeight;
    setDisplayRatio(displayRatio);
  }, [imageOrientation, displayWidth, displayHeight, imageWidth, imageHeight]);

  // Gets initial coordinates, and info on drag start
  const _dragStart = ({
    clientX: startX,
    clientY: startY,
    target,
    dataTransfer,
  }) => {
    // Prevents image ghosting on drag
    dataTransfer.setDragImage(dragImg, 0, 0);
    const { top, left } = target.getBoundingClientRect();
    setMouseCoors({ startX, startY });
    setImageCoors({ top, left });
    setIsDrawing(true);
  };

  // Gets temporary coordinates for the box being selected
  const _drag = debounce(({ clientX: tempX, clientY: tempY }) => {
    if (tempX === 0 && tempY === 0) return;
    const { startX, startY } = mouseCoors;
    const { top: imgTop, left: imgLeft } = imageCoors;
    let selectionBox;
    selectionBox = {
      top: Math.min(startY, tempY) - imgTop,
      left: Math.min(startX, tempX) - imgLeft,
      height: Math.abs(startY - tempY),
      width: Math.abs(startX - tempX),
    };
    if (selectionBox.left < 0) {
      selectionBox.width = selectionBox.width + selectionBox.left;
      selectionBox.left = 0;
    }
    if (selectionBox.top < 0) {
      selectionBox.height = selectionBox.height + selectionBox.top;
      selectionBox.top = 0;
    }
    if (selectionBox.left + selectionBox.width >= imageWidth) {
      selectionBox.width = imageWidth - selectionBox.left;
    }
    if (selectionBox.top + selectionBox.height >= imageHeight) {
      selectionBox.height = imageHeight - selectionBox.top;
    }
    setSelectionBox(selectionBox);
  }, 7);

  // Saves the last coordinates for the resulting annotation
  const _dragEnd = () => {
    const MINIMUM_SIZE = 5;
    const coors = selectionBox;

    const lastId =
      newAnnotations.length > 0
        ? newAnnotations[newAnnotations.length - 1].id
        : 0;
    const newId = lastId + 1;

    const boundingBox = {
      id: newId,
      value: '',
      top: parseInt(coors.top / displayRatio),
      left: parseInt(coors.left / displayRatio),
      height: parseInt(coors.height / displayRatio),
      width: parseInt(coors.width / displayRatio),
      isAnnotation: true,
    };

    const outOfBoundaries =
      coors.left + coors.width > imageWidth ||
      coors.top + coors.height > imageHeight ||
      coors.left < 0 ||
      coors.top < 0;
    const tooSmall = coors.height < MINIMUM_SIZE || coors.width < MINIMUM_SIZE;

    // If annotation is too small or out of boundaries, it is automatically destroyed
    if (outOfBoundaries || tooSmall) return;
    setSelectionBox({});
    setIsDrawing(false);
    setNewAnnotations([...newAnnotations, boundingBox]);
    showSelectConditionModal();
  };

  const showSelectConditionModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    let tempAnnotations = [...newAnnotations];
    tempAnnotations.pop();
    setIsModalVisible(false);
    setNewAnnotations(tempAnnotations);
  };

  const categorySelected = (catId) => {
    let tempAnnotations = [...newAnnotations];
    tempAnnotations[tempAnnotations.length - 1].value =
      CONDITIONS_MAP.categories[catId];
    tempAnnotations[tempAnnotations.length - 1].category_id = catId;
    setIsModalVisible(false);
    setNewAnnotations(tempAnnotations);
  };

  const deleteAnnotation = (id) => {
    let tempNewAnnotations = [...newAnnotations];
    const idx = tempNewAnnotations.findIndex((a) => a.id === id);
    tempNewAnnotations.splice(idx, 1);
    setNewAnnotations(tempNewAnnotations);
  };

  const rotateAndZoomStyles = {
    position: 'relative',
  };

  return (
    <div
      style={{
        ...rotateAndZoomStyles,
      }}
    >
      <Modal
        title="Select category"
        visible={isModalVisible}
        closable={false}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
      >
        {Object.entries(CONDITIONS_MAP.categories)
          .filter(([key]) => categoryIds.includes(parseInt(key)))
          .map(([key, value]) => {
            return (
              <Button
                key={key}
                onClick={() => categorySelected(key)}
                style={{ margin: '5px' }}
              >
                {value}
              </Button>
            );
          })}
      </Modal>
      {!annotations && <LoadingDots />}
      {annotations &&
        annotations !== null &&
        annotations
          .filter(({ id }) => !hiddenAnnotations.includes(id))
          .map((annotation) => {
            return (
              <Lens
                key={annotation.id}
                annotation={annotation}
                displayRatio={displayRatio}
                offsetTop={offsetTop}
                offsetLeft={offsetLeft}
                displayLenses={displayLenses}
                setAnnotations={setAnnotations}
                sidebarActiveAnnotation={sidebarActiveAnnotation}
                conditionsLabelMap={conditionsLabelMap}
                categoryToConditionsMap={categoryToConditionsMap}
              />
            );
          })}
      {newAnnotations &&
        newAnnotations
          .filter(({ id }) => !hiddenAnnotations.includes(id))
          .map((annotation) => {
            return (
              <Lens
                key={annotation.id}
                annotation={annotation}
                displayRatio={displayRatio}
                offsetTop={offsetTop}
                offsetLeft={offsetLeft}
                displayLenses={displayLenses}
                setAnnotations={setAnnotations}
                sidebarActiveAnnotation={sidebarActiveAnnotation}
                deleteAnnotation={deleteAnnotation}
                setNewAnnotations={setNewAnnotations}
                conditionsLabelMap={conditionsLabelMap}
                categoryToConditionsMap={categoryToConditionsMap}
              />
            );
          })}
      <img
        onLoad={handleImageLoaded}
        className="gds-lens-base"
        src={imageSrc}
        alt="Radiograph"
        ref={baseImage}
        style={{
          maxHeight: imgMaxHeight,
          maxWidth: '100%',
        }}
        onDragStart={_dragStart}
        onDrag={_drag}
        onDragEnd={_dragEnd}
      />
      {isDrawing && <SelectionBox coors={selectionBox} />}
    </div>
  );
};

export default LensImage;
