import { InputNumber, Input, Button } from 'antd';
import React from 'react';

const styles = {
  container: {
    width: '100%',
    backgroundColor: '#1f1f1f',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  selector: {
    width: '200px',
    margin: '10px 20px',
  },
  dateSelector: {
    width: '400px',
    margin: '10px 20px',
  },
};

const SingleImageTopbar = ({
  setImageId,
  setOrganizationId,
  tryLoadImage,
  isLoading,
}) => {
  return (
    <div style={styles.container}>
      <InputNumber
        style={styles.selector}
        placeholder="Image ID"
        onChange={(value) => setImageId(value)}
      />
      <Input
        style={styles.selector}
        placeholder="Organization ID"
        onChange={(e) => setOrganizationId(e.target.value)}
      />
      <Button
        disabled={false}
        size="large"
        type="primary"
        loading={isLoading}
        style={styles.selector}
        onClick={() => tryLoadImage()}
      >
        Get Image
      </Button>
    </div>
  );
};

export default SingleImageTopbar;
