import { useCreate } from '../utils/query.hooks';

const annotationCreate = async (
  service,
  { organization_id, annotations, image }
) => {
  const anno = annotations.map((ann) => ({
    category_id: parseInt(ann.category_id),
    condition_id: ann.condition_id && parseInt(ann.condition_id),
    contour: {
      x: ann.left,
      y: ann.top,
      width: ann.width,
      height: ann.height,
    },
  }));
  return service.client.post(
    `${service.ANNOTATION_URL}/${organization_id}/${image.id}`,
    {
      annotations: anno,
      last_processing_transformation: image.last_processing_transformation,
    }
  );
};

function useAnnotationCreate(data, queryConfig) {
  return useCreate.bind(this)(annotationCreate, data, queryConfig);
}

export default useAnnotationCreate;
