import BaseService from '../utils/base.service';
import useListImages from './image.list';
import useListReviewedImages from './review.list';

class ImageReviewService extends BaseService {
  IMAGE_URL = `${BaseService.baseURL}/image`;
  REVIEW_URL = `${BaseService.baseURL}/review`;

  defaultServiceConfig = {
    enabled: false,
    retry: false,
    select: (resp) => resp.data,
  };

  Image = {
    list: useListImages.bind(this),
  };

  Review = {
    list: useListReviewedImages.bind(this),
  };
}

export default new ImageReviewService();
