import React, { useState } from 'react';
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
} from '@ant-design/icons';
import Edentulous from '../../assets/edentulous120.png';
import { useAuth0 } from '@auth0/auth0-react';
import { canSaveToDataset } from '../../utils/auth';
import { Space, Dropdown, Menu } from 'antd';

const translation = {
  l: 'Low',
  m: 'Medium',
  h: 'High',
};

const styles = {
  gdsLens: {
    position: 'absolute',
    cursor: 'pointer',
    opacity: 0.7,
    zIndex: 1,
  },
  gdsLensTooltip: {
    bottom: -30,
    left: -3,
    position: 'absolute',
    whiteSpace: 'nowrap',
    borderRadius: 5,
    fontSize: 'small',
    padding: '1px 8px 0px 8px',
    backgroundColor: 'rgb(245 171 24)',
  },
  gdsLensAction: {
    top: -7,
    right: -8,
    position: 'absolute',
    borderRadius: 20,
    fontSize: '10px',
    padding: '4px',
    width: '18px',
    height: '18px',
    backgroundColor: '#992929',
    color: 'white',
  },
  gdsLensTooltipRed: {
    backgroundColor: '#d85151',
  },
  gdsLensTooltipWhite: {
    backgroundColor: '#fff',
    color: 'black',
  },
};

const Lens = ({
  annotation,
  displayRatio,
  offsetTop,
  offsetLeft,
  displayLenses,
  imageRotation,
  setAnnotations,
  sidebarActiveAnnotation,
  setNewAnnotations,
  deleteAnnotation,
  conditionsLabelMap,
  categoryToConditionsMap,
}) => {
  const { user } = useAuth0();
  const [activeAnnotation, setActiveAnnotation] = useState(null);
  const labelStyle = { transform: `rotate(-${imageRotation}deg)` };
  const isActive =
    annotation.id === activeAnnotation ||
    sidebarActiveAnnotation === annotation.id;
  const isEden = annotation.category_id === 19;
  const showReviewedBy = canSaveToDataset(user);
  const toggleDismissal = () =>
    setAnnotations((prevState) => {
      return prevState.map((ann) => {
        return {
          ...ann,
          is_dismissed:
            ann.id === annotation.id
              ? !annotation.is_dismissed
              : ann.is_dismissed,
        };
      });
    });

  const setConditionId = (conditionId) => {
    const updateAnnotationList = (prevState) => {
      return prevState.map((ann) => {
        return {
          ...ann,
          condition_id:
            ann.id === annotation.id ? conditionId : ann.condition_id,
        };
      });
    };
    if (annotation.isAnnotation) setNewAnnotations(updateAnnotationList);
    else setAnnotations(updateAnnotationList);
  };

  const conditionsMenu = (
    <Menu
      selectedKeys={
        annotation.condition_id ? [`${annotation.condition_id}`] : []
      }
    >
      {categoryToConditionsMap &&
        categoryToConditionsMap[annotation.category_id]?.map((id) => {
          return (
            <Menu.Item
              key={id}
              onClick={() => {
                setConditionId(id);
                if (annotation.is_dismissed) toggleDismissal();
              }}
            >
              {conditionsLabelMap[id]}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <div
      key={annotation.id}
      title={annotation.value}
      style={{
        ...styles.gdsLens,
        top: `${isEden ? 0 : displayRatio * annotation.top + offsetTop}px`,
        left: `${isEden ? 0 : displayRatio * annotation.left + offsetLeft}px`,
        width: `${isEden ? 0 : displayRatio * annotation.width}px`,
        height: `${isEden ? 0 : displayRatio * annotation.height}px`,
        opacity: !displayLenses ? 0 : 1,
        zIndex: isActive ? 300 : 0,
        pointerEvents: !displayLenses ? 'none' : 'auto',
        border: annotation.is_dismissed
          ? isActive
            ? '2px dashed #f15555'
            : '2px dashed #d32222'
          : annotation.isAnnotation
          ? '2px solid rgb(51 144 189)'
          : isActive
          ? '2px solid #2f902f'
          : '2px solid #156a15',
      }}
      onMouseOver={() => setActiveAnnotation(annotation.id)}
      onMouseOut={() => setActiveAnnotation(null)}
    >
      {!annotation.is_dismissed && (
        <CheckOutlined
          style={{
            ...labelStyle,
            ...styles.gdsLensAction,
            backgroundColor: isActive ? '#2f902f' : '#156a15',
          }}
          onClick={toggleDismissal}
        />
      )}
      {annotation.is_dismissed && (
        <CloseOutlined
          style={{
            ...labelStyle,
            ...styles.gdsLensAction,
            backgroundColor: isActive ? '#be3e3e' : '#9e2f2f',
          }}
          onClick={toggleDismissal}
        />
      )}
      {annotation.isAnnotation && (
        <DeleteOutlined
          style={{
            ...labelStyle,
            ...styles.gdsLensAction,
            backgroundColor: 'rgb(51 144 189)',
          }}
          onClick={() => deleteAnnotation(annotation.id)}
        />
      )}
      {isEden ? (
        <img src={Edentulous} alt="edentulous" style={{}} />
      ) : (
        <div
          style={{
            ...labelStyle,
            ...styles.gdsLensTooltip,
            backgroundColor: annotation.is_dismissed
              ? isActive
                ? '#f15555'
                : '#d32222'
              : annotation.isAnnotation
              ? 'rgb(51 144 189)'
              : isActive
              ? '#2f902f'
              : '#156a15',
          }}
        >
          <Space>
            {annotation.condition_id && conditionsLabelMap
              ? `${conditionsLabelMap[annotation.condition_id]}`
              : `${annotation.value}${
                  showReviewedBy && !!translation[annotation.level]
                    ? ' - ' + translation[annotation.level]
                    : ''
                }`}
            {categoryToConditionsMap &&
              categoryToConditionsMap[annotation.category_id] &&
              categoryToConditionsMap[annotation.category_id].length > 1 && (
                <Dropdown overlay={conditionsMenu} placement="bottomLeft">
                  <DownOutlined />
                </Dropdown>
              )}
          </Space>
        </div>
      )}
      {showReviewedBy && annotation.reviewed_by ? (
        <div
          style={{
            ...labelStyle,
            ...styles.gdsLensTooltip,
            bottom: '-55px',
            backgroundColor: '#555555',
          }}
        >
          {`${annotation.reviewed_by}`}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Lens;
