import SidebarAnnotations from './SidebarAnnotations';
import { useAuth0 } from '@auth0/auth0-react';
import { canSaveToDataset } from '../../utils/auth';

const Sidebar = ({
  imageId,
  organizationId,
  createdAt,
  categories,
  humanAnnotatedCategories,
  annotations,
  humanAnnotations,
  hiddenAnnotations,
  setSidebarActiveAnnotation,
  setHiddenAnnotations,
}) => {
  const { user } = useAuth0();
  return (
    <div>
      <div className="site-sidebar">
        <h5 style={{ margin: '4px 0 0 18px' }}>ID: {imageId}</h5>
        {canSaveToDataset(user) ? (
          <h5 style={{ margin: '4px 0 0 18px' }}>{organizationId}</h5>
        ) : null}
        {canSaveToDataset(user) ? (
          <h5 style={{ margin: '4px 0 0 18px' }}>
            {createdAt?.toString()?.split('T')?.[0]}
          </h5>
        ) : null}
        <SidebarAnnotations
          name="Detections"
          categories={categories}
          annotations={annotations}
          hiddenAnnotations={hiddenAnnotations}
          setHiddenAnnotations={setHiddenAnnotations}
          setSidebarActiveAnnotation={setSidebarActiveAnnotation}
        />
        <SidebarAnnotations
          name="Your Annotations"
          categories={humanAnnotatedCategories}
          annotations={humanAnnotations}
          hiddenAnnotations={hiddenAnnotations}
          setHiddenAnnotations={setHiddenAnnotations}
          setSidebarActiveAnnotation={setSidebarActiveAnnotation}
        />
      </div>
    </div>
  );
};

export default Sidebar;
