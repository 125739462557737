import BaseService from '../utils/base.service';
import useImageCreate from './image.create';
import useListDatasets from './dataset.list';
import useListImages from './image.list';

class DatasetService extends BaseService {
  IMAGE_URL = `${BaseService.baseURL}/image`;
  DATASET_URL = `${BaseService.baseURL}/dataset`;

  defaultServiceConfig = {
    enabled: false,
    retry: false,
    select: (resp) => resp.data,
  };

  Image = {
    create: useImageCreate.bind(this),
    list: useListImages.bind(this),
  };
  Dataset = {
    list: useListDatasets.bind(this),
  };
}

export default new DatasetService();
