import dotenv from 'dotenv';

dotenv.config();

export const {
  REACT_APP_ENML_CLOUDFRONT_URL,
  REACT_APP_ENML_CLOUDFRONT_ID,
  REACT_APP_ENML_CLOUDFRONT_KEY,
  REACT_APP_ENML_API,
  REACT_APP_ENML_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_AUDIENCE,
  REACT_APP_IMAGE_REVIEW_API,
  REACT_APP_CONFIG_API,
  REACT_APP_FORCE_CONDITION_RATE,
  REACT_APP_DATASET_FORCE_CONDITION_RATE,
} = process.env;
