import React, { useEffect, useState } from 'react';
import { Layout, Button, Select } from 'antd';
import { DatasetService, EnmlService } from '../../services';
import { parse as parseAnnos } from '../../mappers/annotation.mapper';
import { defaultFailed } from '../../utils/callbacks';
import { isNaN, isEmpty } from 'lodash';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
const { Footer } = Layout;
const { Option } = Select;

const Toolbar = ({
  isReloadingImages,
  setImageIndex,
  annotations,
  humanAnnotations,
  dismissAll,
  reviewable,
  afterApprove,
  categoryIds,
  datasetList,
  setApprovedDismissed,
  image,
  showDatasetSelector = false,
  counterCurrent = 0,
  counterTotal = 0,
  disabled = false,
  saveToEnml = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentDatasetId, setCurrentDatasetId] = useState(null);
  const [currentDatasetSize, setCurrentDatasetSize] = useState(0);

  const checkAllConditionsSet = () => {
    return annotations.every((ann) => {
      return ann.is_dismissed || ann.condition_id;
    });
  };

  const annotationCreateSuccess = ({ data }) => {
    const newAnnos = parseAnnos({ annotations: data || [] }, categoryIds);
    afterApprove({
      annotations: [...newAnnos, ...annotations],
      dataset_id: currentDatasetId,
      image_id: image.id,
    }).then(() => {
      setImageIndex();
      setLoading(false);
      if (showDatasetSelector) setCurrentDatasetSize(currentDatasetSize + 1);
    });
  };

  const imageUpdateSuccess = (data) => {
    setApprovedDismissed(annotations);
    if (humanAnnotations.length > 0) return annotationCreate();

    annotationCreateSuccess({ data: [] });
    setImageIndex();
    setLoading(false);
  };

  const onFailed = (err) => {
    defaultFailed(err);
    setLoading(false);
  };

  const { mutate: imageUpdate } = EnmlService.Image.update(
    { ...reviewable, annotations },
    {
      onSuccess: imageUpdateSuccess,
      onError: onFailed,
    }
  );

  const { mutate: annotationCreateMutate } = EnmlService.Annotation.create(
    { ...reviewable, annotations: humanAnnotations, image },
    {
      onSuccess: annotationCreateSuccess,
      onError: onFailed,
    }
  );

  const annotationCreate = () => {
    const malformedAnnotations = humanAnnotations.filter(
      (ann) => isNaN(ann.height) || isNaN(ann.width)
    );

    if (!isEmpty(malformedAnnotations)) {
      defaultFailed({
        message:
          'You have malformed human annotations. Please delete the malformed annotation to continue',
      });
      setLoading(false);
      return;
    }

    if (humanAnnotations.length > 0) {
      if (image.processing_transformation) {
        defaultFailed({
          message: 'Image currently being transformed, please skip.',
        });
        setLoading(false);
        return annotationCreateSuccess({ data: [] });
      } else {
        return annotationCreateMutate();
      }
    }
  };

  const getDatasetImagesSuccess = (data) => {
    setCurrentDatasetSize(data?.length || 0);
  };

  const { refetch: getDatasetImages } = DatasetService.Image.list(
    { datasetIds: [currentDatasetId] },
    {
      onSuccess: getDatasetImagesSuccess,
      onError: defaultFailed,
    }
  );

  const onChangeDataset = (value) => {
    setCurrentDatasetId(value);
  };

  useEffect(() => {
    if (Number.isInteger(currentDatasetId)) getDatasetImages();
  }, [currentDatasetId, getDatasetImages]);

  const onApprove = async () => {
    setLoading(true);
    if (saveToEnml) imageUpdate();
    else {
      afterApprove({
        annotations: [],
        dataset_id: currentDatasetId,
        image_id: image.id,
      }).then(() => {
        setImageIndex();
        setLoading(false);
        if (showDatasetSelector) setCurrentDatasetSize(currentDatasetSize + 1);
      });
    }
  };

  return (
    <Footer>
      <div
        style={{
          display: 'flex',
          width: '300px',
          justifyContent: 'space-around',
        }}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {counterCurrent}/{counterTotal}
        </span>
        <Button
          danger
          disabled={counterCurrent === 1 || isReloadingImages}
          size="large"
          type="primary"
          loading={loading}
          onClick={() => setImageIndex(-2)}
        >
          Back
          <LeftOutlined />
        </Button>
        <Button
          disabled={disabled}
          size="large"
          type="primary"
          loading={loading}
          onClick={() => setImageIndex()}
        >
          Skip
          <RightOutlined />
        </Button>
      </div>
      {showDatasetSelector && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Dataset Size: {currentDatasetSize}
        </div>
      )}
      {showDatasetSelector && (
        <div
          style={{
            display: 'flex',
            width: '300px',
            justifyContent: 'space-around',
          }}
        >
          <Select
            showSearch
            placeholder="Select a dataset"
            optionFilterProp="children"
            onChange={onChangeDataset}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled}
            style={{ width: '100%' }}
          >
            {datasetList.map(({ id, name }) => {
              return (
                <Option key={id} value={id}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          width: '500px',
          justifyContent: 'space-around',
        }}
      >
        <Button
          danger
          disabled={disabled}
          size="large"
          type="primary"
          loading={loading}
          onClick={dismissAll}
        >
          Dismiss All
          <DeleteOutlined />
        </Button>
        <Button
          disabled={
            disabled ||
            (showDatasetSelector && !currentDatasetId) ||
            (image?.force_condition && !checkAllConditionsSet())
          }
          size="large"
          type="primary"
          loading={loading}
          onClick={() => onApprove()}
        >
          Approve and Proceed
          <ArrowRightOutlined />
        </Button>
      </div>
    </Footer>
  );
};

export default Toolbar;
