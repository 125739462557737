import { CONDITIONS_MAP as labelMap } from '../enums';

export const parse = (enmlResp, categoryIds) => {
  const { annotations } = enmlResp;
  const cleanAnnotations = annotations
    .map((item, index) => {
      return {
        id: item.id,
        is_dismissed: item.is_dismissed,
        top: item.contour.y,
        level: item.level,
        left: item.contour.x,
        width: item.contour.width,
        height: item.contour.height,
        value: labelMap.categories[item.category_id],
        category_id: item.category_id,
        condition_id: item.condition_id,
        reviewed_by: item.reviewed_by,
      };
    })
    .filter((item) => {
      if (item.category_id === 6) {
        return !!item.value && ['h', 'm', 'l'].includes(item.level);
      } else {
        return (
          !!item.value &&
          categoryIds.includes(item.category_id) &&
          ['h', 'm'].includes(item.level)
        );
      }
    });
  return cleanAnnotations;
};
