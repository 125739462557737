export const CONDITIONS_MAP = {
  conditions: {
    1: 'Caries - Enamel only',
    2: 'Caries - Into dentin',
    3: 'Caries - Approaching or into pulp',
    4: 'Bone Loss - 2-4mm from CEJ',
    5: 'Bone Loss - 4-6mm from CEJ',
    6: 'Bone Loss - >6mm',
    7: 'PR',
    8: 'Widened Periodontal Ligament',
    9: 'Crown - metal (including zirconia)',
    10: 'Crown - non-metal',
    11: 'Filling - metal',
    12: 'Filling - non-metal',
    13: 'Root Canal',
    14: 'Implant',
    15: 'Bridge',
    16: 'Inferior Alveolar Nerve',
    17: 'Sinus',
    18: 'Nasal Cavity',
    19: 'Bone',
    20: 'Open Margin',
    21: 'Sub Margin',
    22: 'Recurrent Decay',
  },
  categories: {
    1: 'Crown',
    2: 'BL',
    3: 'PR',
    4: 'Filling',
    5: 'Anatomy',
    6: 'Caries',
    7: 'MD',
    8: 'Implant',
    9: 'WPDL',
    10: 'Root Canal',
    11: 'Bridge',
    12: 'Tooth Numbering',
    14: 'Calc',
  },
};

export const ERRORS = {
  ERROR_429:
    'You have hit your usage limit. Please contact your Pearl account representative for support.',
  ERROR_403:
    'There was an error with your account. Please contact your Pearl account representative for support.',
  NETWORK: 'Network issue: Please check your connection & try again.',
  IMAGE_TYPE:
    'Unsupported image: Pearl’s AI is intended for standard Bitewing & Periapical images.',
  INCOMPLETE_IMAGE:
    'Incomplete results: We’ve had a small hiccup. For complete results please upload again.',
  IMAGE_DIMENSIONS_TOO_SMALL:
    'Pearl’s AI is trained on & intended for high resolution dental office imagery.  Results are not optimized for images smaller than 800x600 pixels.',
  IMAGE_NOT_FOUND: 'Image not found',
  DEFAULT: 'Error retrieving the detections',
};

export const ALL_PATHOLOGIES = [3, 6, 7];
