import ReviewPage from '../ReviewPage';
import Header from '../Header';
import { Layout } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { CONDITIONS_MAP } from '../../enums';
import {
  ConfigurationService,
  ImageReviewService,
  DatasetService,
} from '../../services';
import { defaultFailed } from '../../utils/callbacks';
import Topbar from '../../components/Topbar';
import { canSaveToDataset } from '../../utils/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { REACT_APP_DATASET_FORCE_CONDITION_RATE } from '../../config';

const DatasetReviewPage = ({ hideNewImageAnnotations, saveToEnml, path }) => {
  const { user } = useAuth0();
  const categoriesToUser = Object.keys(CONDITIONS_MAP.categories).map((n) =>
    parseInt(n, 10)
  );
  const [imageQueue, setImageQueue] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [resetQueue, setResetQueue] = useState(false);
  const [imageToDataset, setImageToDataset] = useState(null);
  const [datasetList, setDatasetList] = useState([]);
  const [imageReviewQuery, setImageReviewQuery] = useState({});
  const [waitingForSelection, setWaitingForSelection] = useState(true);
  const [excludedDatasets, setExcludedDatasets] = useState([]);
  const [unfilteredQueue, setUnfilteredQueue] = useState(null);
  const categoryList = Object.entries(CONDITIONS_MAP.categories);
  const first = useRef(true);

  const organizationListSuccess = (data) => setOrganizationList(data);

  const { isLoading: isLoadingConfig } = ConfigurationService.Organization.list(
    {},
    {
      enabled: true,
      onSuccess: organizationListSuccess,
      onError: defaultFailed,
    }
  );

  const datasetListSuccess = (data) => setDatasetList(data);

  const { isLoading: isLoadingDatasets } = DatasetService.Dataset.list(
    {},
    {
      enabled: true,
      onSuccess: datasetListSuccess,
      onError: defaultFailed,
    }
  );

  const getExcludedImagesSuccess = (data) => {
    const excludedImages =
      data?.map(({ enml_image_id }) => enml_image_id) || [];
    const filteredQueue = unfilteredQueue.filter(({ image_id }) => {
      return !excludedImages.includes(image_id);
    });
    setUnfilteredQueue(null);
    if (resetQueue) {
      setImageQueue(filteredQueue);
      setResetQueue(false);
    } else setImageQueue([...imageQueue, ...filteredQueue]);
  };

  const { refetch: getExcludedImages } = DatasetService.Image.list(
    {
      enml_image_ids: unfilteredQueue?.map(({ image_id }) => image_id),
      datasetIds: excludedDatasets,
    },
    {
      onSuccess: getExcludedImagesSuccess,
      onError: defaultFailed,
    }
  );

  const getImagesSuccess = (data) => {
    setUnfilteredQueue(data);
  };

  const { refetch: getImages, isLoading: isLoadingImages } =
    ImageReviewService.Review.list(imageReviewQuery, {
      onSuccess: getImagesSuccess,
      onError: defaultFailed,
    });

  const defaultFailedPromise = async (err) => {
    defaultFailed(err);
  };

  const saveToDatasetSuccess = () => {
    return new Promise((resolve) => resolve());
  };

  const { mutate: saveToDataset } = DatasetService.Image.create(
    imageToDataset,
    {
      onSuccess: saveToDatasetSuccess,
      onError: defaultFailedPromise,
    }
  );

  useEffect(() => {
    if (unfilteredQueue) getExcludedImages();
  }, [unfilteredQueue, getExcludedImages]);

  useEffect(() => {
    if (!first.current) setResetQueue(true);
    first.current = false;
  }, [imageReviewQuery]);

  useEffect(() => {
    if (imageToDataset) saveToDataset();
  }, [imageToDataset, saveToDataset]);

  const afterApprove = async (image) => {
    setImageToDataset(image);
  };

  const forceConditionRate = parseInt(
    REACT_APP_DATASET_FORCE_CONDITION_RATE || 0
  );

  return canSaveToDataset(user) ? (
    <Layout>
      <Header path={path} />
      <Topbar
        setCategoryIds={setCategoryIds}
        organizationList={organizationList}
        categoryList={categoryList}
        categoryIds={categoryIds}
        setImageReviewQuery={setImageReviewQuery}
        setWaitingForSelection={setWaitingForSelection}
        setExcludedDatasets={setExcludedDatasets}
        datasetList={datasetList}
        isLoading={isLoadingImages || resetQueue}
      />
      <Layout style={{ height: 'calc(100vh - 188px)' }}>
        <ReviewPage
          categoryIds={categoryIds.length > 0 ? categoryIds : categoriesToUser}
          getImages={getImages}
          getImagesWhenFinished={false}
          isLoadingImages={isLoadingImages}
          imageQueue={imageQueue}
          isLoadingConfig={isLoadingConfig}
          isLoadingDatasets={isLoadingDatasets}
          afterApprove={afterApprove}
          datasetList={datasetList}
          showDatasetSelector={true}
          resetQueue={resetQueue}
          waitingForSelection={waitingForSelection}
          imgMaxHeight={'calc(100vh - 220px)'}
          toggleWithCtrl={true}
          saveToEnml={saveToEnml}
          hideNewImageAnnotations={hideNewImageAnnotations}
          forceConditionRate={forceConditionRate}
        />
      </Layout>
    </Layout>
  ) : (
    'Forbidden'
  );
};

export default DatasetReviewPage;
