import { useCreate } from '../utils/query.hooks';

const imageCreate = async (service, { dataset_id, image_id, annotations }) => {
  const anno = annotations.map((ann) => ({
    enml_annotation_id: ann.id,
    category_id: parseInt(ann.category_id),
    is_dismissed: ann.is_dismissed,
  }));

  return service.client.post(`${service.IMAGE_URL}`, {
    dataset_id,
    enml_image_id: image_id,
    annotations: anno,
  });
};

function useImageCreate(data, queryConfig) {
  return useCreate.bind(this)(imageCreate, data, queryConfig);
}

export default useImageCreate;
