import { useGet } from '../utils/query.hooks';

const listImages = async (service, { enml_image_ids, datasetIds }) => {
  return service.client.get(`${service.IMAGE_URL}`, {
    params: {
      enml_image_ids: enml_image_ids?.join(','),
      dataset_ids: datasetIds?.join(','),
    },
  });
};

function useListImages(data, queryConfig) {
  return useGet.bind(this)(
    ['listImages', Object.values(data)],
    listImages,
    data,
    queryConfig
  );
}

export default useListImages;
