import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as IconProfile } from '../../assets/icon-profile.svg';
import { Link } from 'react-router-dom';
import { Layout, Popover, Menu } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { canSaveToDataset } from '../../utils/auth';

const { Header } = Layout;
const styles = {
  container: {
    width: '100%',
    backgroundColor: '#304156',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  menu: {
    backgroundColor: '#304156',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  logo: {
    boxSizing: 'content-box',
    fill: '#fff',
    width: '90px',
    height: 'auto',
    padding: '5px',
    marginLeft: '30px',
  },
  link: {
    boxSizing: 'content-box',
    fill: '#fff',
    paddingTop: '24px',
    cursor: 'pointer',
    paddingRight: '26px',
  },
  info: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },

  info__title: {
    color: '#fff',
    margin: 0,
  },

  info__subtitle: {
    margin: 0,
    marginTop: '5px',
  },
};

export default function HeaderFull({ title, subtitle = null, path }) {
  const { user, logout } = useAuth0();
  return (
    <Header style={styles.container}>
      <div style={styles.menu}>
        <Logo style={{ ...styles.logo }} />
        {canSaveToDataset(user) && (
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[path]}
            style={styles.menu}
          >
            <Menu.Item key="/">
              <Link to="/">Image Review</Link>
            </Menu.Item>
            <Menu.Item key="/reviewer">
              <Link to="/reviewer">Reviewer Review</Link>
            </Menu.Item>
            <Menu.Item key="/dataset">
              <Link to="/dataset">Dataset Review</Link>
            </Menu.Item>
            <Menu.Item key="/imagelevel">
              <Link to="/imagelevel">Image Level Dataset</Link>
            </Menu.Item>
          </Menu>
        )}
      </div>
      <div style={styles.info}>
        <h1 style={styles.info__title}>{title}</h1>
        {subtitle && <h3 style={styles.info__subtitle}>{subtitle}</h3>}
      </div>
      <div>
        <Popover
          placement="bottom"
          title={<span>{user.nickname.toUpperCase()}</span>}
          content={
            <div>
              <Link
                to=""
                onClick={() => {
                  logout({ returnTo: window.location.href });
                }}
              >
                Logout
              </Link>
            </div>
          }
          trigger="click"
        >
          <IconProfile style={styles.link} />
        </Popover>
      </div>
    </Header>
  );
}
