import { useGet } from '../utils/query.hooks';

const listReviewedImages = async (
  service,
  {
    organizationIds,
    categoryIds,
    reviewerIds,
    beginDate,
    endDate,
    limit = 20,
    offset = 1,
  }
) => {
  return service.client.get(`${service.REVIEW_URL}`, {
    params: {
      organization_ids: organizationIds.join(','),
      category_ids: categoryIds.join(','),
      reviewer_ids: reviewerIds,
      begin_date: beginDate,
      end_date: endDate,
      limit,
      offset,
    },
  });
};

function useListReviewedImages(data, queryConfig) {
  return useGet.bind(this)(
    ['listReviewedImages', Object.values(data)],
    listReviewedImages,
    data,
    queryConfig
  );
}

export default useListReviewedImages;
