import BaseService from '../utils/base.service';
import useListOrganization from './organization.list';

class ConfigurationService extends BaseService {
  ORGANIZATION_URL = `${BaseService.baseURL}/organization`;

  defaultServiceConfig = {
    enabled: false,
    retry: false,
    select: (resp) => resp.data,
  };

  Organization = {
    list: useListOrganization.bind(this),
  };
}

export default new ConfigurationService();
