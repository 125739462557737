import { useMutation, useQuery } from 'react-query';

export function useGet(name, func, data, queryConfig = {}) {
  return useQuery(name, () => func(this, data), {
    ...this.defaultQueryConfig,
    ...this.defaultServiceConfig,
    ...queryConfig,
  });
}

export function useCreate(func, data, queryConfig = {}) {
  return useMutation(() => func(this, data), {
    ...this.defaultQueryConfig,
    ...this.defaultServiceConfig,
    ...queryConfig,
  });
}

export function useUpdate(func, data, queryConfig = {}) {
  return useMutation(() => func(this, data), {
    ...this.defaultQueryConfig,
    ...this.defaultServiceConfig,
    ...queryConfig,
  });
}
