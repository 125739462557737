import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Alert, Layout, Spin } from 'antd';
import LensImage from '../LensImage';
import Toolbar from '../Toolbar';
import Sidebar from '../Sidebar';
import { parse as parseAnnosForCategories } from '../../mappers/category.mapper';
import { parse as parseAnnos } from '../../mappers/annotation.mapper';
import { EnmlService } from '../../services';
import { defaultFailed } from '../../utils/callbacks';
import './reviewPage.css';
import { isEmpty } from 'lodash';

const { Content } = Layout;

const ReviewPage = ({
  categoryIds,
  isLoadingImages,
  imageQueue,
  getImages,
  isLoadingConfig,
  isLoadingDatasets,
  afterApprove,
  resetQueue,
  datasetList,
  showDatasetSelector = false,
  waitingForSelection = false,
  getImagesWhenFinished = true,
  imgMaxHeight = 'calc(100vh - 180px)',
  toggleWithCtrl = false,
  hideNewImageAnnotations = false,
  saveToEnml = true,
  forceConditionRate = 0,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(null);
  const [annotations, setAnnotations] = useState(null);
  const [humanAnnotations, setHumanAnnotations] = useState([]);
  const [queuePosition, setQueuePosition] = useState(0);
  const [isImageQueueFinished, setIsImageQueueFinished] = useState(true);
  const [loadedImageQueue, setLoadedImageQueue] = useState([]);
  const [categories, setCategories] = useState([]);
  const [humanAnnotatedCategories, setHumanAnnotatedCategories] = useState([]);
  const [hiddenAnnotations, setHiddenAnnotations] = useState([]);
  const [sidebarActiveAnnotation, setSidebarActiveAnnotation] = useState(null);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [imagesToLoad, setImagesToLoad] = useState([]);
  const [conditionsLabelMap, setConditionsLabelMap] = useState({});
  const [categoryToConditionsMap, setCategoryToConditionsMap] = useState({});
  const [imagesToReload, setImagesToReload] = useState([])
  const first = useRef(true);

  const getConditionsSuccess = (data) => {
    let conditionsLabelMap = {};
    let categoryToConditionsMap = {};
    data.forEach(({ id, category_id, name }) => {
      conditionsLabelMap[id] = name;
      if (categoryToConditionsMap[category_id]) {
        categoryToConditionsMap[category_id].push(id);
      } else {
        categoryToConditionsMap[category_id] = [id];
      }
    });

    setConditionsLabelMap(conditionsLabelMap);
    setCategoryToConditionsMap(categoryToConditionsMap);
  };

  const { isLoading: isLoadingConditions } = EnmlService.Condition.list(
    {},
    {
      enabled: true,
      onSuccess: getConditionsSuccess,
      onError: defaultFailed,
    }
  );

  const getImageSuccess = (data) => {
    const newImages = data.map(({ data, loadedImage }) => ({
      id: data.id,
      organization_id: data.organization_id,
      created_at: data.image_created_date,
      src: data.signed_url,
      width: loadedImage.naturalWidth,
      height: loadedImage.naturalHeight,
      annotations: data.annotations,
      processing_transformation: data.processing_transformation,
      last_processing_transformation: data.last_processing_transformation,
      force_condition: Math.random() * 100 <= forceConditionRate,
    }));

    setLoadedImageQueue([...loadedImageQueue, ...newImages]);
    setIsImageQueueFinished(false);
    setImagesToLoad([]);
  };

  const { refetch: getImage } = EnmlService.Image.load(imagesToLoad, {
    onSuccess: getImageSuccess,
    onError: defaultFailed,
  });

  const reloadImageSuccess = (data) => {
    const reloadedImages = data.map(({ data, loadedImage }) => ({
      id: data.id,
      organization_id: data.organization_id,
      created_at: data.image_created_date,
      src: data.signed_url,
      width: loadedImage.naturalWidth,
      height: loadedImage.naturalHeight,
      annotations: data.annotations,
      processing_transformation: data.processing_transformation,
      last_processing_transformation: data.last_processing_transformation,
      force_condition: Math.random() * 100 <= forceConditionRate,
    }));

    setLoadedImageQueue(reloadedImages)
  }

  const { refetch: reloadImages, isLoading: isReloadingImages } = EnmlService.Image.load(imagesToReload, {
    onSuccess: reloadImageSuccess,
    onError: defaultFailed
  })

  const dismissAll = () =>
    setAnnotations((state) =>
      state.map((ann) => ({ ...ann, is_dismissed: true }))
    );

  const toggleAll = () => {
    const ids = annotations.map((a) => a.id);
    if (ids.every((a) => hiddenAnnotations.includes(a)))
      setHiddenAnnotations([]);
    else setHiddenAnnotations(ids);
  };

  const handleToggleAnnotationCommand = useCallback(
    (event) => {
      if (event.code === 'KeyA' && (!toggleWithCtrl || ctrlPressed))
        toggleAll();
      if (event.code === 'KeyD' && ctrlPressed) dismissAll();
      if (event.code === 'ControlLeft') setCtrlPressed(false);
      event.stopPropagation();
    },
    // eslint-disable-next-line
    [ctrlPressed, annotations, hiddenAnnotations]
  );

  const handleDown = useCallback((event) => {
    if (event.code === 'ControlLeft') setCtrlPressed(true);
  }, []);

  const setNewImageInfo = (newImage) => {
    const newAnno = parseAnnos(newImage, categoryIds);
    const ids = newAnno.map((a) => a.id);
    setAnnotations(newAnno);
    setCategories(parseAnnosForCategories(newAnno));
    setCurrentImage(newImage);
    setHumanAnnotations([]);
    if (hideNewImageAnnotations) setHiddenAnnotations(ids);
    else setHiddenAnnotations([]);
  };

  useEffect(() => {
    const filtered = loadedImageQueue.filter((im) => im.id >= 0);
    if (isLoading && filtered.length > 0) {
      setNewImageInfo(filtered[0]);
      setIsImageQueueFinished(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isLoading, loadedImageQueue]);

  useEffect(() => {
    if (imagesToLoad.length > 0) getImage();
  }, [imagesToLoad, getImage]);

  useEffect(() => {
    if (!isEmpty(imagesToReload)) reloadImages()
  }, [imagesToReload, reloadImages])

  useEffect(() => {
    document.addEventListener('keydown', handleDown);
    document.addEventListener('keyup', handleToggleAnnotationCommand);
    return () => {
      document.removeEventListener('keydown', handleDown);
      document.removeEventListener('keyup', handleToggleAnnotationCommand);
    };
  }, [handleToggleAnnotationCommand, handleDown]);

  useEffect(() => {
    if (first.current && imageQueue.length) {
      first.current = false;
      setImagesToLoad(imageQueue.slice(0, 4));
    }
  }, [imageQueue]);

  useEffect(() => {
    setHumanAnnotatedCategories(parseAnnosForCategories(humanAnnotations));
  }, [humanAnnotations]);

  useEffect(() => {
    if (resetQueue) {
      setIsLoading(true);
      setCurrentImage(null);
      setAnnotations(null);
      setHumanAnnotations([]);
      setQueuePosition(0);
      setIsImageQueueFinished(true);
      setLoadedImageQueue([]);
      setCategories([]);
      setHumanAnnotatedCategories([]);
      setHiddenAnnotations([]);
      setSidebarActiveAnnotation(null);
      setImagesToLoad([]);
      first.current = true;
      getImages();
    }
  }, [resetQueue, getImages]);

  const checkImageQueue = (newPosition, removeIndex) => {
    if (
      getImagesWhenFinished &&
      imageQueue.length - newPosition < 6 &&
      removeIndex >= 0
    ) {
      getImages();
    }
  };

  const reloadCurrentImages = async ({image_id}) => {
    const image = loadedImageQueue.find(i => i.id === image_id)
    setImagesToReload([...imageQueue, { ...image, image_id: image.id }])
  }

  const setImageIndex = async (removeIndex = 0) => {
    const newPosition = queuePosition + 1 + removeIndex;
    setHumanAnnotations([]);
    setHiddenAnnotations([]);
    if (loadedImageQueue[newPosition]) {
      const image = loadedImageQueue[newPosition];
      setNewImageInfo(image);
      setQueuePosition(newPosition);
      const len = loadedImageQueue.length;
      if (removeIndex >= 0 && len - newPosition < 4) {
        await setImagesToLoad(imageQueue.slice(len, len + 2));
      }
    }
    setIsImageQueueFinished(!loadedImageQueue[newPosition]);
    checkImageQueue(newPosition, removeIndex);
  };

  const doAfterApprove = async (image) => {
    const currentIds = loadedImageQueue[queuePosition].annotations.map(
      (anno) => anno.id
    );
    image.annotations.forEach((anno) => {
      if (!currentIds.includes(anno.id)) {
        const toSaveAnno = {
          ...anno,
          contour: {
            x: anno.left,
            y: anno.top,
            width: anno.width,
            height: anno.height,
          },
        };
        loadedImageQueue[queuePosition].annotations.push(toSaveAnno);
      }
    });
    reloadCurrentImages(image)
    afterApprove(image);
  };

  const setApprovedDismissed = async (annotations) => {
    annotations.forEach((anno) => {
      loadedImageQueue[queuePosition].annotations.forEach((ann) => {
        if (ann.id === anno.id) ann.is_dismissed = anno.is_dismissed;
      });
    });
    setLoadedImageQueue(loadedImageQueue);
  };

  return (
    <Content className="site-layout">
      <div className="site-top">
        <div className="site-layout-background">
          <div className="image-layer">
            {waitingForSelection ? (
              'Select which images to load'
            ) : isLoading ? (
              <Spin />
            ) : !isImageQueueFinished ? (
              <div>
                {currentImage?.force_condition && (
                  <Alert
                    style={{ textAlign: 'center' }}
                    message="A condition must be selected for each accepted annotation in this image"
                    type="info"
                    showIcon
                  />
                )}
                <LensImage
                  image={currentImage}
                  annotations={annotations}
                  hiddenAnnotations={hiddenAnnotations}
                  setAnnotations={setAnnotations}
                  sidebarActiveAnnotation={sidebarActiveAnnotation}
                  newAnnotations={humanAnnotations}
                  setNewAnnotations={setHumanAnnotations}
                  categoryIds={categoryIds}
                  imgMaxHeight={imgMaxHeight}
                  conditionsLabelMap={conditionsLabelMap}
                  categoryToConditionsMap={categoryToConditionsMap}
                />
              </div>
            ) : (
              <Spin />
            )}
          </div>
        </div>
        <Sidebar
          imageId={loadedImageQueue?.[queuePosition]?.id}
          organizationId={loadedImageQueue?.[queuePosition]?.organization_id}
          createdAt={loadedImageQueue?.[queuePosition]?.created_at}
          categories={categories}
          humanAnnotatedCategories={humanAnnotatedCategories}
          annotations={annotations}
          humanAnnotations={humanAnnotations}
          hiddenAnnotations={hiddenAnnotations}
          setSidebarActiveAnnotation={setSidebarActiveAnnotation}
          setHiddenAnnotations={setHiddenAnnotations}
        />
      </div>
      <Toolbar
        counterCurrent={queuePosition + 1}
        counterTotal={imageQueue.length}
        isReloadingImages={isReloadingImages}
        disabled={
          isLoadingImages ||
          isLoadingConfig ||
          isLoadingDatasets ||
          isLoadingConditions ||
          isImageQueueFinished ||
          !loadedImageQueue[queuePosition]
        }
        setApprovedDismissed={setApprovedDismissed}
        setImageIndex={setImageIndex}
        annotations={annotations}
        humanAnnotations={humanAnnotations}
        dismissAll={dismissAll}
        reviewable={imageQueue[queuePosition]}
        afterApprove={doAfterApprove}
        categoryIds={categoryIds}
        datasetList={datasetList}
        showDatasetSelector={showDatasetSelector}
        image={currentImage}
        saveToEnml={saveToEnml}
      />
    </Content>
  );
};

export default ReviewPage;
