import ReviewPage from '../../components/ReviewPage';
import Header from '../../components/Header';
import { Layout } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { ALL_PATHOLOGIES } from '../../enums';
import { useState, useEffect, useRef } from 'react';
import { ConfigurationService, ImageReviewService } from '../../services';
import { defaultFailed } from '../../utils/callbacks';
import { REACT_APP_FORCE_CONDITION_RATE } from '../../config';

const ImageReview = () => {
  const { user } = useAuth0();
  const urlPart = 'https://hellopearl.com/';
  const ongoing = user[`${urlPart}active_human_in_loop_ongoing`];
  const inputMode = user[`${urlPart}active_human_in_loop_mode`];
  const mode = !ongoing ? inputMode || 'FUTURE' : 'FUTURE';
  const userOrg = user[`${urlPart}organization`];
  const organizations = user[`${urlPart}active_human_in_loop_organization`];
  const userCategoryIds = user[`${urlPart}active_human_in_loop_category`];
  const categoriesToUser = ongoing ? ALL_PATHOLOGIES : userCategoryIds;
  const [imageQueue, setImageQueue] = useState([]);
  const [categoryIds, setCategoryIds] = useState(userCategoryIds || [6]);
  const [organizationIds, setOrganizationIds] = useState(null);
  const first = useRef(true);

  const organizationListSuccess = (data) =>
    setOrganizationIds(organizations || data);

  const { isLoading: isLoadingConfig } = ConfigurationService.Organization.list(
    {
      enable_review: true,
    },
    {
      enabled: true,
      onSuccess: organizationListSuccess,
      onError: defaultFailed,
    }
  );

  const getImagesSuccess = (data, override) => {
    if (categoryIds.length !== 3 && !data?.length && !userCategoryIds) {
      return setCategoryIds(ALL_PATHOLOGIES);
    }
    if (first.current) setImageQueue(data);
    else setImageQueue([...imageQueue, ...data]);
    first.current = false;
  };

  const { refetch: getImages, isLoading: isLoadingImages } =
    ImageReviewService.Image.list(
      {
        userOrg,
        mode,
        organizationIds,
        categoryIds,
      },
      {
        onSuccess: getImagesSuccess,
        onError: defaultFailed,
      }
    );

  useEffect(() => {
    if (organizationIds) getImages();
    // eslint-disable-next-line
  }, [organizationIds, categoryIds]);

  const afterApprove = (_) => {
    return new Promise((resolve) => resolve());
  };

  const forceConditionRate = parseInt(REACT_APP_FORCE_CONDITION_RATE || 0);

  return (
    <Layout>
      <Header path="/" />
      <Layout style={{ height: 'calc(100vh - 128px)' }}>
        <ReviewPage
          organizations={organizations}
          categoryIds={categoriesToUser}
          getImages={getImages}
          isLoadingImages={isLoadingImages}
          imageQueue={imageQueue}
          organizationIds={organizationIds}
          isLoadingConfig={isLoadingConfig}
          afterApprove={afterApprove}
          forceConditionRate={forceConditionRate}
        />
      </Layout>
    </Layout>
  );
};

export default ImageReview;
