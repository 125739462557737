import { Collapse, Button, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

const SidebarAnnotations = ({
  name,
  categories,
  annotations,
  hiddenAnnotations,
  setHiddenAnnotations,
  setSidebarActiveAnnotation,
}) => {
  const toggleMouseEnter = (annotation) => (e) => {
    setSidebarActiveAnnotation(annotation.id);
  };

  const toggleMouseLeave = (annotation) => (e) => {
    setSidebarActiveAnnotation(null);
  };

  const toggleAll = (category_id) => {
    const annotationsWithCategory = annotations
      .filter((a) => a.category_id === category_id)
      .map((a) => a.id);

    if (annotationsWithCategory.every((e) => hiddenAnnotations.includes(e))) {
      setHiddenAnnotations(
        hiddenAnnotations.filter((a) => !annotationsWithCategory.includes(a))
      );
    } else {
      setHiddenAnnotations([
        ...hiddenAnnotations.filter(
          (a) => !annotationsWithCategory.includes(a)
        ),
        ...annotationsWithCategory,
      ]);
    }
  };

  const toggleAnnotation = (annotation) => (e) => {
    if (hiddenAnnotations.includes(annotation.id)) {
      setHiddenAnnotations(
        hiddenAnnotations.filter((a) => a !== annotation.id)
      );
    } else {
      setHiddenAnnotations([...hiddenAnnotations, annotation.id]);
    }
  };

  return (
    <div>
      <h4 style={{ margin: '4px 0 0 18px' }}>{name}</h4>
      <Collapse>
        {categories.map((category, categoryIndex) => (
          <Panel
            header={
              <Tooltip
                placement="topLeft"
                title={`Show and hide annotations ONLY. Clicking anything here will NOT dismiss ${category.name}`}
              >
                <span onClick={() => toggleAll(category.id)}>
                  <span className="toggle-all-icon">
                    {annotations
                      .filter((a) => a.category_id === category.id)
                      .map((a) => a.id)
                      .every((e) => hiddenAnnotations.includes(e)) ? (
                      <EyeInvisibleOutlined />
                    ) : (
                      <EyeOutlined />
                    )}
                  </span>
                  {category.name}
                </span>
              </Tooltip>
            }
            key={categoryIndex}
          >
            {annotations &&
              annotations
                .filter((a) => a.category_id === category.id)
                .map((annotation, annotationIndex) => (
                  <Button
                    key={annotationIndex}
                    onMouseOver={toggleMouseEnter(annotation)}
                    onMouseOut={toggleMouseLeave(annotation)}
                    icon={
                      hiddenAnnotations.includes(annotation.id) ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )
                    }
                    type="primary"
                    ghost
                    onClick={toggleAnnotation(annotation)}
                  >
                    {`${annotation.value} ${annotationIndex + 1}`}
                  </Button>
                ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default SidebarAnnotations;
