import { Button, DatePicker, InputNumber, Input, Select } from 'antd';
import React, { useState } from 'react';

const { Option } = Select;
const { RangePicker } = DatePicker;

const styles = {
  container: {
    width: '100%',
    backgroundColor: '#1f1f1f',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  selector: {
    width: '200px',
    margin: '10px 20px',
  },
  dateSelector: {
    width: '400px',
    margin: '10px 20px',
  },
};

const Topbar = ({
  organizationList,
  categoryList,
  categoryIds,
  setCategoryIds,
  setImageReviewQuery,
  setWaitingForSelection,
  setExcludedDatasets,
  datasetList,
  isLoading,
}) => {
  const [organizationIds, setOrganizationIds] = useState([]);
  const [reviewerIds, setReviewerIds] = useState([]);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(null);
  const [offset, setOffset] = useState(null);

  const toSelectOption = (value) => {
    return <Option key={value}>{value}</Option>;
  };

  const toCategorySelectOption = ([key, value]) => {
    return <Option key={key}>{value}</Option>;
  };

  const onChangeOrganizations = (value) => {
    setOrganizationIds(value);
  };

  const onChangeCategories = (value) => {
    setCategoryIds(value.map((n) => parseInt(n, 10)));
  };

  const onChangeReviewers = ({ target: { value } }) => {
    setReviewerIds(value);
  };

  const onChangeDataset = (value) => {
    const datasetIds = datasetList.map(({ id }) => id);
    if (value.includes(-1)) setExcludedDatasets(datasetIds);
    else setExcludedDatasets(value);
  };

  const onOkDateRange = ([beginDate, endDate]) => {
    setBeginDate(beginDate?.toISOString());
    setEndDate(endDate?.toISOString());
  };

  const onChangeDateRange = (dateRange) => {
    if (!dateRange) {
      setBeginDate(null);
      setEndDate(null);
    }
  };

  const fetchImages = () => {
    setWaitingForSelection(false);
    setImageReviewQuery({
      organizationIds,
      reviewerIds,
      categoryIds,
      beginDate,
      endDate,
      limit,
      offset,
    });
  };

  return (
    <div style={styles.container}>
      <Select
        mode="multiple"
        allowClear
        style={styles.selector}
        placeholder="All Organizations"
        onChange={onChangeOrganizations}
      >
        {organizationList.map(toSelectOption)}
      </Select>
      <Select
        mode="multiple"
        allowClear
        style={styles.selector}
        placeholder="All Categories"
        onChange={onChangeCategories}
      >
        {categoryList.map(toCategorySelectOption)}
      </Select>
      <Input
        allowClear
        style={styles.selector}
        placeholder="All Reviewers"
        onChange={onChangeReviewers}
      />
      <RangePicker
        allowClear
        showTime={{ format: 'HH:mm' }}
        format="YYYY-MM-DD HH:mm"
        onOk={onOkDateRange}
        onChange={onChangeDateRange}
        style={styles.dateSelector}
      />
      <InputNumber
        min={1}
        max={10000}
        style={styles.selector}
        placeholder="Start Index (default=1)"
        onChange={(value) => setOffset(value)}
      />
      <InputNumber
        min={1}
        max={500}
        style={styles.selector}
        placeholder="Chunk Size (default=50)"
        onChange={(value) => setLimit(value)}
      />
      <Select
        showSearch
        allowClear
        mode="multiple"
        placeholder="Exclude datasets"
        optionFilterProp="children"
        onChange={onChangeDataset}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={styles.selector}
      >
        {[{ id: -1, name: 'All datasets' }, ...datasetList].map(
          ({ id, name }) => {
            return (
              <Option key={id} value={id}>
                {name}
              </Option>
            );
          }
        )}
      </Select>
      <Button
        disabled={false}
        size="large"
        type="primary"
        loading={isLoading}
        style={styles.selector}
        onClick={() => fetchImages()}
      >
        Get Images
      </Button>
    </div>
  );
};

export default Topbar;
