import React from 'react';
import './SelectionBox.css';

const SelectionBox = ({ coors }) => (
  <div className="detection detection--selection" style={coors}>
    <div className="detection-grid detection-grid--h" />
    <div className="detection-grid detection-grid--v" />
  </div>
);

export default SelectionBox;
