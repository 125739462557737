import DatasetReviewPage from '../../components/DatasetReviewPage';

const DatasetReview = () => {
  return (
    <DatasetReviewPage
      saveToEnml={true}
      hideNewImageAnnotations={false}
      path="/dataset"
    />
  );
};

export default DatasetReview;
