import { useUpdate } from '../utils/query.hooks';

const imageUpdate = async (
  service,
  { organization_id, image_id, annotations }
) => {
  const updates = [
    {
      id: image_id,
      annotations: annotations.map((ann) =>
        ann.is_dismissed && ann.condition_id
          ? {
              id: ann.id,
              is_dismissed: ann.is_dismissed,
              is_accepted: !ann.is_dismissed,
            }
          : {
              id: ann.id,
              is_dismissed: ann.is_dismissed,
              is_accepted: !ann.is_dismissed,
              condition_id: ann.condition_id,
            }
      ),
    },
  ];
  return service.client.put(`${service.IMAGE_URL}/${organization_id}`, {
    images: updates,
  });
};

function useImageUpdate(data, queryConfig) {
  return useUpdate.bind(this)(imageUpdate, data, queryConfig);
}

export default useImageUpdate;
