import createClient from './client.factory';

class BaseService {
  static baseURL = '/api/v1';
  client;
  defaultQueryConfig;

  initialize(
    options,
    getAccessToken,
    interceptors = {},
    defaultQueryConfig = {}
  ) {
    this.defaultQueryConfig = defaultQueryConfig;
    this.client = createClient(options, getAccessToken, interceptors);
  }
}

export default BaseService;
