import axios from 'axios';
import {
  DEFAULT_CONFIG,
  DEFAULT_ERROR_REQUEST_INTERCEPTOR,
  DEFAULT_ERROR_RESPONSE_INTERCEPTOR,
  DEFAULT_SUCCESS_REQUEST_INTERCEPTOR,
  DEFAULT_SUCCESS_RESPONSE_INTERCEPTOR,
} from './constants';

const createClient = (clientConfig, getAccessToken, interceptors = {}) => {
  const { options, ...config } = clientConfig;

  const client = axios.create({ ...DEFAULT_CONFIG, ...config });
  const {
    successRequestInterceptor,
    errorRequestInterceptor,
    successResponseInterceptor,
    errorResponseInterceptor,
  } = interceptors;

  const getToken = async (request) => {
    const token = await getAccessToken();
    request.headers['Authorization'] = `Bearer ${token}`;
    return request;
  };
  client.interceptors.request.use(getToken);

  client.interceptors.request.use(
    successRequestInterceptor || DEFAULT_SUCCESS_REQUEST_INTERCEPTOR,
    errorRequestInterceptor || DEFAULT_ERROR_REQUEST_INTERCEPTOR
  );

  client.interceptors.request.use(
    successRequestInterceptor || DEFAULT_SUCCESS_REQUEST_INTERCEPTOR,
    errorRequestInterceptor || DEFAULT_ERROR_REQUEST_INTERCEPTOR
  );
  client.interceptors.response.use(
    successResponseInterceptor || DEFAULT_SUCCESS_RESPONSE_INTERCEPTOR,
    errorResponseInterceptor || DEFAULT_ERROR_RESPONSE_INTERCEPTOR
  );

  return client;
};

export default createClient;
