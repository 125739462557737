import Header from '../../components/Header';
import SingleImageTopbar from '../../components/SingleImageTopbar';
import LensImage from '../../components/LensImage';
import Sidebar from '../../components/Sidebar';
import { Layout, Spin } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { EnmlService } from '../../services';
import { parse as parseAnnosForCategories } from '../../mappers/category.mapper';
import { parse as parseAnnos } from '../../mappers/annotation.mapper';
import { defaultFailed } from '../../utils/callbacks';
import { canSaveToDataset } from '../../utils/auth';

const { Content } = Layout;

const ReviewerReview = () => {
  const { user } = useAuth0();
  const categoriesToUser = [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 14];
  const [imageQueue, setImageQueue] = useState([]);
  const [imageId, setImageId] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [annotations, setAnnotations] = useState(null);
  const [humanAnnotations, setHumanAnnotations] = useState([]);
  const [loadedImageQueue, setLoadedImageQueue] = useState([]);
  const [isImageQueueFinished, setIsImageQueueFinished] = useState(true);
  const [categories, setCategories] = useState([]);
  const [humanAnnotatedCategories, setHumanAnnotatedCategories] = useState([]);
  const [hiddenAnnotations, setHiddenAnnotations] = useState([]);
  const [sidebarActiveAnnotation, setSidebarActiveAnnotation] = useState(null);
  const [conditionsLabelMap, setConditionsLabelMap] = useState({});
  const [categoryToConditionsMap, setCategoryToConditionsMap] = useState({});

  const getConditionsSuccess = (data) => {
    let conditionsLabelMap = {};
    let categoryToConditionsMap = {};
    data.forEach(({ id, category_id, name }) => {
      conditionsLabelMap[id] = name;
      if (categoryToConditionsMap[category_id]) {
        categoryToConditionsMap[category_id].push(id);
      } else {
        categoryToConditionsMap[category_id] = [id];
      }
    });

    setConditionsLabelMap(conditionsLabelMap);
    setCategoryToConditionsMap(categoryToConditionsMap);
  };

  const { isLoading: isLoadingConditions } = EnmlService.Condition.list(
    {},
    {
      enabled: true,
      onSuccess: getConditionsSuccess,
      onError: defaultFailed,
    }
  );

  const getImageSuccess = (data) => {
    const newImages = data.map(({ data, loadedImage }) => ({
      id: data.id,
      organization_id: data.organization_id,
      created_at: data.image_created_date,
      src: data.signed_url,
      width: loadedImage.naturalWidth,
      height: loadedImage.naturalHeight,
      annotations: data.annotations,
    }));
    setLoadedImageQueue(newImages);
    setIsImageQueueFinished(false);
  };

  const { refetch: getImage } = EnmlService.Image.load(imageQueue, {
    onSuccess: getImageSuccess,
    onError: defaultFailed,
  });

  const setNewImageInfo = (newImage) => {
    const newAnno = parseAnnos(newImage, categoriesToUser);
    setAnnotations(newAnno);
    setCategories(parseAnnosForCategories(newAnno));
    setCurrentImage(newImage);
    setHumanAnnotations([]);
    setHiddenAnnotations([]);
  };

  useEffect(() => {
    const filtered = loadedImageQueue.filter((im) => im.id >= 0);
    if (isLoading && filtered.length > 0) {
      setNewImageInfo(filtered[0]);
      setIsImageQueueFinished(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isLoading, loadedImageQueue]);

  useEffect(() => {
    if (imageQueue.length > 0) getImage();
  }, [imageQueue, getImage]);

  useEffect(() => {
    setHumanAnnotatedCategories(parseAnnosForCategories(humanAnnotations));
  }, [humanAnnotations]);

  const tryLoadImage = (_) => {
    if (canSaveToDataset(user)) {
      setLoadedImageQueue([]);
      setIsLoading(true);
      setImageQueue([{ image_id: imageId, organization_id: organizationId }]);
    }
  };

  return (
    <Layout>
      <Header path="/reviewer" />
      <SingleImageTopbar
        setOrganizationId={setOrganizationId}
        setImageId={setImageId}
        tryLoadImage={tryLoadImage}
      />
      <Layout style={{ height: 'calc(100vh - 128px)' }}>
        <Content className="site-layout">
          <div className="site-top">
            <div className="site-layout-background">
              <div className="image-layer">
                {isLoading || isLoadingConditions ? (
                  <Spin />
                ) : !isImageQueueFinished ? (
                  <LensImage
                    image={currentImage}
                    annotations={annotations}
                    hiddenAnnotations={hiddenAnnotations}
                    setAnnotations={setAnnotations}
                    sidebarActiveAnnotation={sidebarActiveAnnotation}
                    newAnnotations={humanAnnotations}
                    setNewAnnotations={setHumanAnnotations}
                    categoryIds={categoriesToUser}
                    conditionsLabelMap={conditionsLabelMap}
                    categoryToConditionsMap={categoryToConditionsMap}
                  />
                ) : (
                  'Please Search for Image'
                )}
              </div>
            </div>
            <Sidebar
              imageId={loadedImageQueue?.[0]?.id}
              organizationId={loadedImageQueue?.[0]?.organization_id}
              createdAt={loadedImageQueue?.[0]?.created_at}
              categories={categories}
              humanAnnotatedCategories={humanAnnotatedCategories}
              annotations={annotations}
              humanAnnotations={humanAnnotations}
              hiddenAnnotations={hiddenAnnotations}
              setSidebarActiveAnnotation={setSidebarActiveAnnotation}
              setHiddenAnnotations={setHiddenAnnotations}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ReviewerReview;
