import React from 'react';
import './style/App.css';
import ImageReview from './views/ImageReview';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Auth0Provider,
  withAuthenticationRequired,
  Auth0Context,
} from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import {
  ConfigurationService,
  ImageReviewService,
  EnmlService,
  DatasetService,
} from './services';
import DatasetReview from './views/DatasetReview';
import ReviewerReview from './views/ReviewerReview';
import ImageLevelDatasetReview from './views/ImageLevelDatasetReview';

const {
  REACT_APP_AUTH_DOMAIN: AUTH_DOMAIN,
  REACT_APP_AUTH_CLIENT_ID: AUTH_CLIENT_ID,
  REACT_APP_AUTH_AUDIENCE: AUTH_AUDIENCE,
  REACT_APP_CONFIG_API,
  REACT_APP_IMAGE_REVIEW_API,
  REACT_APP_ENML_API,
  REACT_APP_CV_DATASET_API,
} = process.env;

export const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const onRedirectCallback = (appState) => {
  window.history.replaceState({}, document.title, window.location.pathname)
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};
export const ProtectedRoute = ({ component, ...args }) => (
  <Route
    render={(props) => {
      let Component = withAuthenticationRequired(component, {
        // If using a Hash Router, you need to pass the hash fragment as `returnTo`
        // returnTo: () => window.location.hash.substr(1),
      });
      return <Component {...props} />;
    }}
    {...args}
  />
);

const deferred = (() => {
  const props = {};
  props.promise = new Promise((resolve) => (props.resolve = resolve));
  return props;
})();

export const getAccessToken = async () => {
  const getToken = await deferred.promise;
  return getToken();
};

function App() {
  return (
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={AUTH_CLIENT_ID}
      audience={AUTH_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently }) => {
          deferred.resolve(getAccessTokenSilently);
          ConfigurationService.initialize(
            { baseURL: REACT_APP_CONFIG_API },
            getAccessToken
          );
          ImageReviewService.initialize(
            { baseURL: REACT_APP_IMAGE_REVIEW_API },
            getAccessToken
          );
          EnmlService.initialize(
            { baseURL: REACT_APP_ENML_API },
            getAccessToken
          );
          DatasetService.initialize(
            { baseURL: REACT_APP_CV_DATASET_API },
            getAccessToken
          );
          return (
            <QueryClientProvider client={queryClient}>
              <Router history={history}>
                <Switch>
                  <ProtectedRoute exact path="/" component={ImageReview} />
                  <ProtectedRoute
                    exact
                    path="/dataset"
                    component={DatasetReview}
                  />
                  <ProtectedRoute
                    exact
                    path="/reviewer"
                    component={ReviewerReview}
                  />
                  <ProtectedRoute
                    exact
                    path="/imagelevel"
                    component={ImageLevelDatasetReview}
                  />
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </Router>
            </QueryClientProvider>
          );
        }}
      </Auth0Context.Consumer>
    </Auth0Provider>
  );
}

function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        No page found at <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;
