import { useGet } from '../utils/query.hooks';

const imagesLoad = async (service, list) => {
  const promises = list.map(
    ({ organization_id, image_id }) =>
      // eslint-disable-next-line
      new Promise(async (resolve, reject) => {
        const resp = await service.client.get(
          `${service.IMAGE_URL}/${organization_id}/${image_id}`
        );
        const data = resp.data;
        let loadedImage = new Image();
        loadedImage.src = data.signed_url;
        loadedImage.addEventListener('load', () =>
          resolve({ data, loadedImage })
        );
      })
  );
  const data = await Promise.all(promises);
  return { data };
};

function useImagesLoad(data, queryConfig) {
  return useGet.bind(this)(['imagesLoad', data], imagesLoad, data, queryConfig);
}

export default useImagesLoad;
