import { useGet } from '../utils/query.hooks';

const listDatasets = async (service, _) => {
  return service.client.get(`${service.DATASET_URL}`, null);
};

function useListDatasets(data, queryConfig) {
  return useGet.bind(this)(
    ['listDatasets', Object.values(data)],
    listDatasets,
    data,
    queryConfig
  );
}

export default useListDatasets;
