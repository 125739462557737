import { useGet } from '../utils/query.hooks';

const listImages = async (
  service,
  { userOrg, mode, organizationIds, categoryIds, limit = 20 }
) => {
  return service.client.post(`${service.IMAGE_URL}/${userOrg}`, {
    mode,
    organization_ids: organizationIds,
    category_ids: categoryIds,
    limit,
  });
};

function useListImages(data, queryConfig) {
  return useGet.bind(this)(
    ['listImages', Object.values(data)],
    listImages,
    data,
    queryConfig
  );
}

export default useListImages;
