import React from 'react';
import PropTypes from 'prop-types';

const LoadingDots = ({ size }) => {
  return (
    <div className="gds-loading">
      <div
        className={`gds-loading__dot ${
          size && size === 'small' ? '' : ' gds-loading__dot--lg'
        }`}
      />
    </div>
  );
};

LoadingDots.propTypes = {
  size: PropTypes.string,
};

export default LoadingDots;
