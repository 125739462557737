import { useGet } from '../utils/query.hooks';

const listOrganization = async (service, { enable_review }) => {
  return service.client.get(`${service.ORGANIZATION_URL}`, {
    params: { enable_review },
  });
};

function useListOrganization(data, queryConfig) {
  return useGet.bind(this)(
    ['listOrgs', Object.values(data)],
    listOrganization,
    data,
    queryConfig
  );
}

export default useListOrganization;
