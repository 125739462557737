import { useGet } from '../utils/query.hooks';

const listConditions = async (service) => {
  return service.client.get(service.CONDITION_URL, {
    params: {},
  });
};

function useListConditions(data, queryConfig) {
  return useGet.bind(this)(
    ['listConditions', Object.values(data)],
    listConditions,
    data,
    queryConfig
  );
}

export default useListConditions;
