export const DEFAULT_CONFIG = {
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const DEFAULT_SUCCESS_REQUEST_INTERCEPTOR = async (config) => {
  return config;
};

export const DEFAULT_ERROR_REQUEST_INTERCEPTOR = (error) => {
  console.log(error); // for debug
  return Promise.reject(error);
};

export const DEFAULT_SUCCESS_RESPONSE_INTERCEPTOR = (response) => {
  return response;
};

export const DEFAULT_ERROR_RESPONSE_INTERCEPTOR = (error) => {
  console.log(error); // for debug
  return Promise.reject(error);
};
