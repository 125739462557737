import BaseService from '../utils/base.service';
import useImageUpdate from './image.update';
import useImagesLoad from './image.load';
import useAnnotationCreate from './annotation.create';
import useListConditions from './conditions.list';

class EnmlService extends BaseService {
  IMAGE_URL = `${BaseService.baseURL}/image`;
  CONDITION_URL = `${BaseService.baseURL}/condition`;
  ANNOTATION_URL = `${BaseService.baseURL}/annotation`;

  defaultServiceConfig = {
    enabled: false,
    retry: false,
    select: (resp) => resp.data,
  };

  Image = {
    update: useImageUpdate.bind(this),
    load: useImagesLoad.bind(this),
  };

  Annotation = {
    create: useAnnotationCreate.bind(this),
  };

  Condition = {
    list: useListConditions.bind(this),
  };
}

export default new EnmlService();
